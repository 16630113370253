import NextLink from "next/link";

import { Divider, Link } from "@releaseit/ui";

export function Custom404() {
  return (
    <div className="my-48 flex flex-col items-center gap-y-12">
      <div className="text-center">
        <p className="text-sm font-medium tracking-wide text-gray-800">404 ERROR</p>
        <h1 className="text-gray-900 mt-2 text-4xl font-medium tracking-tight sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-2 text-base text-gray-600">
          Sorry, we couldn&apos;t find the page you&apos;re looking for.
        </p>
        <div className="mt-6">
          <NextLink href="/" passHref>
            <Link isSubtle isStrong>
              Go back home<span aria-hidden="true"> &rarr;</span>
            </Link>
          </NextLink>
        </div>
      </div>

      <nav className="flex gap-x-4">
        <NextLink href="/contact" passHref>
          <Link variant="neutral" isSubtle isStrong className="text-sm">
            Contact
          </Link>
        </NextLink>

        <Divider orientation="vertical" isDecorative />

        <NextLink href="/page/faqs" passHref>
          <Link variant="neutral" isSubtle isStrong className="text-sm">
            FAQs
          </Link>
        </NextLink>
      </nav>
    </div>
  );
}

export default Custom404;
